import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import PrintIcon from '@material-ui/icons/Print';

const styles = theme => ({
    root: {
        position: 'fixed',
        width: '100%',
        bottom: 0,
        zIndex: 1100,
    },
});

const Footer = (props) => {
    const { classes } = props;

    return (
    <BottomNavigation
        showLabels
        className={classes.root}
    >
        <BottomNavigationAction label="(503) 992-6359" href="tel:5039926359" icon={<PhoneIcon />} />
            <BottomNavigationAction label="Office@GSTestingLLC.com" href="mailto:Office@GSTestingLLC.com" icon={<EmailIcon />} />
        <BottomNavigationAction label="(503) 992-6526" href="tel:5039926526" icon={<PrintIcon />} />
        </BottomNavigation>
    );
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);