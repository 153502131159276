import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const styles = {
    p: {
        marginTop: 4,
    }
};

const HoursOfOperation = (props) => {
    const { classes } = props;

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">
                    Hours of Operation
                </Typography>
                <Typography variant="body1" className={classes.p}>
                    <strong>Standard Hours: </strong>Monday thru Thursday: 8am. - 5pm. Friday: 8am. - 4pm.
                </Typography>
                <Typography variant="body1" className={classes.p}>
                    <strong>Emergency Hours: </strong>On call 24 hours a day.
                </Typography>
            </CardContent>
        </Card>
    );
};

HoursOfOperation.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HoursOfOperation);