import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

const styles = {
    avatar: {
        margin: 10,
        width: 60,
        height: 60,
    },
    grow: {
        flexGrow: 1,
    },
    svg: {
        width: 24,
        height: 24,
    },
};  

const Header = (props) => {
    const { classes } = props;

    return (
        <AppBar position="static" color="default">
            <Toolbar>
                <Avatar
                className={classes.avatar}
                alt="G.S. Testing, llc"
                src="/img/logo.png"
                />
                <Typography variant="h6" color="inherit" className={classes.grow}>
                G.S. Testing, LLC
                </Typography>
                <a href="https://www.facebook.com/gstesting/" target="_blank" rel="noopener noreferrer">
                    <svg className={classes.svg} viewBox="0 0 24 24">
                        <path fill="#757575" d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z" />
                    </svg>
                </a>
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
