import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { List, ListItem } from '@material-ui/core';
import FullWidthTabs from './FullWidthTabs';

const TestsAndServices = (props) => {
    const tabs = [
        {
            label: "Department of Transportation Laws and Regulations",
            children: [<List
                            dense
                        >
                            <ListItem button href="http://www.dot.gov/odapc/" component="a" target="_blank" >Office of Drug &amp; Alcohol Policy &amp; Compliance</ListItem>
                            <ListItem button href="http://www.phmsa.dot.gov/pipeline/drug" component="a" target="_blank" >PHMSA Drug &amp; Alcohol Regulations</ListItem>
                            <ListItem button href="http://www.dot.gov/odapc/random-testing-rates" component="a" target="_blank" >DOT Random Testing Rates</ListItem>
                        </List>
            ]
        },
        {
            label: "Recovery and Support",
            children: [<List
                            dense
                        >
                            <ListItem button href="http://www.aa.org/" component="a" target="_blank" >Alcoholics Anonymous</ListItem>
                            <ListItem button href="http://www.na.org/" component="a" target="_blank" >Narcotics Anonymous</ListItem>
                            <ListItem button href="http://store.samhsa.gov/home" component="a" target="_blank" >Substance Abuse and Mental Health Services Administration</ListItem>
                            <ListItem button href="http://www.drugabuse.gov/" component="a" target="_blank" >National Institute on Drug Abuse</ListItem>
                            <ListItem button href="http://drugrehab.com/" component="a" target="_blank" >DrugRehab.com</ListItem>
                            <ListItem button href="http://rehabcenter.net/" component="a" target="_blank" >RehabCenter.net</ListItem>
                            <ListItem button href="https://communitylivingabove.org/" component="a" target="_blank" >Community Living Above</ListItem>
                            <ListItem button href="https://www.faminsrec.org/" component="a" target="_blank" >Family Inspired Recovery</ListItem>
                            <ListItem button href="https://lifestreamtherapy.net/" component="a" target="_blank" >LifeSTREAM Therapy LLC</ListItem>
                            <ListItem button href="https://harmonyacademyrhs.org/" component="a" target="_blank" >Harmony Academy</ListItem>
                        </List>
            ],
        }
    ];

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">
                    Resources
                </Typography>

                <FullWidthTabs tabs={tabs}></FullWidthTabs>

            </CardContent>
        </Card>
    );
};

TestsAndServices.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default TestsAndServices;