import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const styles = {
    p: {
        marginTop: 4,
    }
};

const AboutUs = (props) => {
    const { classes } = props;

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">
                    About Us
                </Typography>
                <Typography variant="body1" className={classes.p}>
                    G.S. Testing, llc operates a full service DOT and non-DOT collection site.
                </Typography>
                <Typography variant="body1" className={classes.p}>
                    We provide fixed location and on-site collections performed by
                    professional, DOT-qualified collectors and Breath Alcohol Technicians.
                </Typography>
                <Typography variant="body1" className={classes.p}>
                Our office is located just 1000 feet off of I-5 at exit 290 and is within two minutes of HWY 217 and I-205.
                Collection time is approximately 10 minutes and test results are generally reported between 24 and 48 hours later.
                </Typography>
                <Typography variant="body1" className={classes.p}>
                    <Link href="https://katu.com/news/local/drug-users-shocked-to-find-fentanyl-in-their-system-shows-the-evolution-of-opioid-crisis" target="_blank" rel="noopener noreferrer" variant="body1">See us on the news!</Link>
                </Typography>
            </CardContent>
        </Card>
    );
};

AboutUs.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AboutUs);