import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

function TabContainer({ children, dir }) {
    return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
        {children}
    </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
};

const styles = theme => ({
    root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    },
});

class FullWidthTabs extends React.Component {
    state = {
    value: 0,
    };

    handleChange = (event, value) => {
    this.setState({ value });
    };

    handleChangeIndex = index => {
    this.setState({ value: index });
    };

    render() {
        const { classes, theme, tabs } = this.props;

        return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={this.state.value}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    fullWidth
                >
                    {
                        tabs.map(tab => (<Tab label={tab.label} />))
                    }
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={this.state.value}
                onChangeIndex={this.handleChangeIndex}
            >
                {
                    tabs.map(tab => (<TabContainer dir={theme.direction}>{tab.children}</TabContainer>))
                }
            </SwipeableViews>
        </div>
        );
    }
}

FullWidthTabs.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    tabs: PropTypes.array.isRequired,
};

export default withStyles(styles, { withTheme: true })(FullWidthTabs);