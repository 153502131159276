import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { List, ListItem, ListItemText } from '@material-ui/core';
import FullWidthTabs from './FullWidthTabs';

const TestsAndServices = (props) => {
    const tabs = [
        {
            label: "We are able to provide the following tests and services",
            children: [<List
                            dense
                        >
                            <ListItem>
                                <ListItemText primary="Urine Collection - Lab Based" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Rapid/Instant Urine Testing" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="DOT and Non-DOT Testing" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Breath Alcohol Testing" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Saliva Testing" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Hair Collection" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Family Intervention (Adolescents/Young Adults)" />
                            </ListItem>
                        </List>
            ]
        },
        {
            label: "For the following reasons",
            children: [<List
                            dense
                        >
                            <ListItem>
                                <ListItemText primary="Random" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Pre-Employment" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Reasonable Suspicion" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Post-Accident" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Return to Duty" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Follow Up" />
                            </ListItem>
                        </List>
            ],
        }
    ];

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">
                    Tests and Services Offered
                </Typography>

                <FullWidthTabs tabs={tabs}></FullWidthTabs>

            </CardContent>
        </Card>
    );
};

TestsAndServices.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default TestsAndServices;