import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { Paper, Grid } from '@material-ui/core';

import Header from './Header';
import AboutUs from './AboutUs';
import TestsAndServices from './TestsAndServices';
import Footer from './Footer';
import HoursOfOperation from './HoursOfOperation';
import Location from './Location';
import Resources from './Resources';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    padding: theme.spacing.unit * 2,
  },
});

const App = (props) => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <CssBaseline />
      
      <Paper elevation={1}>
      <Header />
      <Grid 
        container
      >
        <Grid item xs={12} md={4}>
          <Grid item xs={12} className={classes.grid}>
            <AboutUs />  
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            <HoursOfOperation />
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            <Location />
          </Grid>
        </Grid>
        
        <Grid item xs={12} md={4} className={classes.grid}>
          <TestsAndServices />
        </Grid>
        
        <Grid item xs={12} md={4} className={classes.grid}>
          <Resources />
        </Grid>
      
      </Grid>
      <Footer/>
      </Paper>
      
    </div>
  );
}

export default withStyles(styles)(App);
