import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const styles = {
    p: {
        marginTop: 4,
    },
    gmaps: {
        border: 0,
    }
};

const Location = (props) => {
    const { classes } = props;

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">
                    Location
                </Typography>
                <iframe className={classes.gmaps} title="17649 S.W. 65th Avenue, Lake Oswego, OR 97035" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d22413.663134162645!2d-122.742617!3d45.395063!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549572e7b8879271%3A0x6c4ab761e8239827!2s17649+SW+65th+Ave%2C+Lake+Oswego%2C+OR+97035!5e0!3m2!1sen!2sus!4v1428272771073" width="400" height="300"></iframe>
            </CardContent>
        </Card>
    );
};

Location.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Location);